import React from "react";

import {
    Container,
    Grid,
    Typography,
    Box,
    Checkbox,
    Alert,
    TextField,
    LinearProgress,
    Button,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    FormLabel,
    Divider,
    Avatar,
    CircularProgress,
} from "@mui/material";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

import { MathJax, MathJaxContext } from "better-react-mathjax";

import { useSelector, useDispatch } from "react-redux";
import { setName, setStepId, setScore } from "./studentSlice";

import axios from "axios";

const QA = [
    {
        question: "$$ \\frac{1}{2} - \\frac{3}{4} = $$",
        answers: [
            "\\(- \\frac{1}{2} \\)",
            "\\(- \\frac{1}{4} \\)",
            "\\( \\frac{1}{4} \\)",
        ],
        correctAnswer: 1,
    },
    {
        question: "$$ \\frac{1}{3} - \\frac{2}{3} = $$",
        answers: [
            "\\(- \\frac{1}{3} \\)",
            "\\(- \\frac{2}{3} \\)",
            "\\( 1 \\)",
        ],
        correctAnswer: 0,
    },
    {
        question: "$$ 1 + \\frac{7}{8} = $$",
        answers: [
            "\\( \\frac{8}{7} \\)",
            "\\( \\frac{6}{8} \\)",
            "\\( \\frac{15}{8} \\)",
        ],
        correctAnswer: 2,
    },
    {
        question: "$$ \\frac{3}{5} - \\frac{5}{3} = $$",
        answers: [
            "\\( 0 \\)",
            "\\(- \\frac{16}{15} \\)",
            "\\( \\frac{16}{15} \\)",
        ],
        correctAnswer: 1,
    },
    {
        question: "$$ \\frac{1}{1000} - 1 = $$",
        answers: [
            "\\(- \\frac{999}{1000} \\)",
            "\\( \\frac{999}{1000} \\)",
            "\\( \\frac{1000}{999} \\)",
        ],
        correctAnswer: 0,
    },
    {
        question: "$$ \\frac{1}{2} - \\frac{1}{3} + \\frac{1}{4} = $$",
        answers: [
            "\\(- \\frac{5}{12} \\)",
            "\\( \\frac{5}{12} \\)",
            "\\( \\frac{1}{24} \\)",
        ],
        correctAnswer: 1,
    },
    {
        question: "$$ \\frac{-1}{2} \\times \\frac{3}{4} - 1 = $$",
        answers: [
            "\\(- \\frac{1}{8} \\)",
            "\\( \\frac{1}{8} \\)",
            "\\( - \\frac{11}{8} \\)",
        ],
        correctAnswer: 2,
    },
    {
        question:
            "Soit \\( x \\) un nombre décimal non nul, Si: $$ \\frac{-2}{3x} = \\frac{4}{3} $$ Alors: $$ x = $$",
        answers: [
            "\\(- \\frac{1}{2} \\)",
            "\\( \\frac{1}{2} \\)",
            "\\( \\frac{1}{3} \\)",
        ],
        correctAnswer: 0,
    },
    {
        question:
            "Soient \\( x \\) et \\( y \\) deux nombres décimaux non nuls, tels que \\( xy = 2 \\), Si: $$ \\frac{1}{x} - \\frac{1}{y} = 1 $$ Alors: $$ x-y = $$",
        answers: ["\\( 2 \\)", "\\( - 2 \\)", "\\( 1 \\)"],
        correctAnswer: 1,
    },
    {
        question:
            "Soit \\( n \\) un entier non nul, Si: $$ n + \\frac{1}{n} = \\frac{10}{3} $$ Alors: $$ n^2 + \\frac{1}{n^2} = $$",
        answers: [
            "\\( \\frac{82}{9} \\)",
            "\\( \\frac{80}{9} \\)",
            "\\( \\frac{16}{9} \\)",
        ],
        correctAnswer: 0,
    },
];

const QAForm = () => {
    const name = useSelector((state) => state.name);
    var step_id = useSelector((state) => state.step_id);
    var score = useSelector((state) => state.score);

    const [index, setIndex] = React.useState(-1);

    const dispatch = useDispatch();

    const nextCallback = async () => {
        // if (step_id === 10) {
        //     alert("You have completed the test");
        //     return;
        // }

        if (index === QA[step_id - 1].correctAnswer) {
            score += 2;
            setScore(score);
        }

        step_id += 1;

        localStorage.setItem("step_id", step_id);
        dispatch(setStepId(step_id));

        localStorage.setItem("score", score);
        dispatch(setScore(score));

        if (step_id === 11) {
            try {
                const res = await axios.post(
                    "https://send.pageclip.co/cTZtT2segAkOuzCClUBXu8ulgtfYRyWL",
                    {
                        status: "completed",
                        name: name,
                        note: score + ' / 20',
                    }
                );
            } catch (e) {}
        }

        document.location.reload();
    };

    React.useEffect(() => {
        // Call MathJax.typeset() after the component has rendered
        // MathJax.typeset();
    }, [step_id]);

    return (
        <Grid container spacing={2} flexDirection={"column"}>
            <Grid item xs={12} md={8} lg={6}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 2,
                    }}
                >
                    <Typography variant="h5" sx={{ color: "black" }}>
                        Question {step_id}/10
                    </Typography>
                    <Avatar
                        sx={{ bgcolor: "black" }}
                        overlap="circular"
                        variant="dot"
                    >
                        {name[0].toUpperCase()}
                    </Avatar>
                </Box>
                <LinearProgress variant="determinate" value={step_id * 10} />
            </Grid>
            <Grid item xs={12} md={8} lg={6}>
                <FormControl sx={{ width: "100%" }}>
                    <FormLabel
                        id="demo-radio-buttons-group-label"
                        sx={{ my: 4 }}
                    >
                        <MathJax>
                            <Typography variant="h5" sx={{ color: "black" }}>
                                {QA[step_id - 1].question}
                            </Typography>
                        </MathJax>
                    </FormLabel>
                    <Divider />
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            my: 2,
                        }}
                    >
                        {QA[step_id - 1].answers.map((answer, index) => (
                            <FormControlLabel
                                key={index}
                                value={index}
                                control={<Radio />}
                                label={
                                    <MathJax>
                                        <Typography variant="h5">
                                            {answer}
                                        </Typography>
                                    </MathJax>
                                }
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setIndex(index);
                                    }
                                }}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={8} lg={6} my={2}>
                <Button
                    variant="contained"
                    fullWidth
                    sx={{
                        backgroundColor: "#FF2E63",
                        "&:hover": {
                            backgroundColor: "#FF2E63",
                        },
                    }}
                    endIcon={<DoubleArrowIcon />}
                    onClick={nextCallback}
                    disabled={index === -1}
                >
                    Suivant
                </Button>
            </Grid>
        </Grid>
    );
};

const RegistrationForm = () => {
    const [name, _setName] = React.useState("");
    const [error, setError] = React.useState(false);

    const [loading, setLoading] = React.useState(false);

    const dispatch = useDispatch();

    const register = async () => {
        if (loading) return;
        setLoading(true);
        const nameRegex = /^[a-zA-Z]{3,}(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
        if (!nameRegex.test(name)) {
            setLoading(false);
            setError(true);
            return;
        }

        try {
            const res = await axios.post(
                "https://send.pageclip.co/cTZtT2segAkOuzCClUBXu8ulgtfYRyWL",
                {
                    status: "registered",
                    name: name,
                    'user-agent': navigator.userAgent
                }
            );
        } catch (e) {}
        setLoading(false);

        // alert("Registered successfully");
        localStorage.setItem("name", name);
        localStorage.setItem("step_id", 1);
        localStorage.setItem("score", 0);
        dispatch(setName(name));
    };
    return (
        <Grid container spacing={2} flexDirection={"column"}>
            <Grid item xs={12} md={8} lg={6}>
                <Typography variant="h5" sx={{ color: "black", mb: 2 }}>
                    Inscription
                </Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={6}>
                <TextField
                    id="name"
                    label="Nom et Prénom"
                    variant="outlined"
                    fullWidth
                    size="small"
                    onChange={(e) => {
                        if (error) setError(false);
                        _setName(e.target.value);
                    }}
                />
            </Grid>

            <Grid item xs={12} md={8} lg={6} my={2}>
                <Button
                    variant="contained"
                    fullWidth
                    sx={{
                        backgroundColor: "#FF2E63",
                        "&:hover": {
                            backgroundColor: "#FF2E63",
                        },
                    }}
                    // endIcon={<DoubleArrowIcon />}
                    onClick={register}
                >
                    {
                        loading ? (<CircularProgress size={24} sx={{ color: 'white' }} />): "Continue"
                    }
                </Button>
            </Grid>

            <Grid item xs={12} md={8} lg={6} my={2}>
                {error && (
                    <Alert severity="error">
                        Veuillez entrer un nom valide
                    </Alert>
                )}
            </Grid>
        </Grid>
    );
};

const ResultBox = () => {
    const name = useSelector((state) => state.name);
    var score = useSelector((state) => state.score);

    return (
        <Grid container spacing={2} flexDirection={"column"}>
            <Grid item xs={12} md={8} lg={6}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 2,
                    }}
                >
                    <Typography variant="h5" sx={{ color: "black" }}>
                        Résultat:
                    </Typography>
                    <Avatar
                        sx={{ bgcolor: "black" }}
                        overlap="circular"
                        variant="dot"
                    >
                        {name[0].toUpperCase()}
                    </Avatar>
                </Box>
            </Grid>

            <Grid item xs={12} md={8} lg={6}>
                <Divider />
            </Grid>
            <Grid item xs={12} md={8} lg={6}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "start",
                        mb: 2,
                    }}
                >
                    <Typography variant="h5" sx={{ color: "black" }}>
                        Nom et Prénom:{" "}
                        <Typography
                            variant="span"
                            sx={{ color: "orangered", display: "inline" }}
                        >
                            {name}
                        </Typography>
                    </Typography>

                    <Divider sx={{ my: 2 }} />

                    <Typography variant="h5" sx={{ color: "black" }}>
                        Note:{" "}
                        <Typography
                            variant="span"
                            sx={{ color: "dodgerblue", display: "inline" }}
                        >
                            {score} / 20
                        </Typography>
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    );
};

function App() {
    const name = useSelector((state) => state.name);
    const step_id = useSelector((state) => state.step_id);
    const dispatch = useDispatch();
    return (
        <MathJaxContext>
            <Container fixed sx={{ mt: 4 }}>
                {name ? (
                    step_id > 10 ? (
                        <ResultBox />
                    ) : (
                        <QAForm />
                    )
                ) : (
                    <RegistrationForm />
                )}
            </Container>
        </MathJaxContext>
    );
}

export default App;
