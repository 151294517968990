import { createSlice } from "@reduxjs/toolkit";

const studentSlice = createSlice({
    name: "student",
    initialState: {
        name: localStorage.getItem("name"),
        step_id: parseInt(localStorage.getItem("step_id")) || 1,
        score: parseInt(localStorage.getItem("score")) || 0,
    },
    reducers: {
        setName: (state, action) => {
            state.name = action.payload;
        },
        setStepId: (state, action) => {
            state.step_id = action.payload;
        },
        setScore: (state, action) => {
            state.score = action.payload;
        },
    },
});

export const { setName, setStepId, setScore } = studentSlice.actions;

export default studentSlice.reducer;
